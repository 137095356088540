import imageInput from '@/mixins/image-input.js';
import TeamsPostModel from '../teams/models/teams-post.model';
import {mapState} from 'vuex';

export default {
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
            if (this.item.upType === 3) {
                this.item.upRank = 0;
            }
            let data = new TeamsPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("teams/setItem", formData).then((res) => {
                if (res) {
                    this.item = {};
                    this.resfresh = true;
                }
            });
        }
    },
    computed: {
        ...mapState({
            upTypes: (state) => state.teams.upTypes,
        }),
    },
    mixins: [imageInput],
}
